import { useState, useEffect, useRef } from 'react';

const useDebounce = (value:any, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState();
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
      timer.current = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => clearTimeout(timer.current!);
    }, [value, delay]);
  
    return debouncedValue;
  };

export default useDebounce;
