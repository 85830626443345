// constants
import { SimplyAskAction, SimplyAskDashboardState } from './constants';

const INIT_STATE = {
    
};

const SimplyAskDashboard = (
    state: SimplyAskDashboardState = INIT_STATE,
    action: {
        type: SimplyAskAction;
        payload: any;
    }
) => {
    switch (action.type) {
        case SimplyAskAction.SET_SIMPLY_ASK_DASHBOARD:
            return {
                ...state,
                simplyAskDashboard: action.payload,
            };
        default:
            return { ...state };
    }
};

export default SimplyAskDashboard;
