import { useEffect, useState } from 'react';

// actions
import { changeSimplyAskDashboard } from '../redux/actions';

// hooks
import { useRedux } from '.';

export default function useSimplyAsk(dashboard: any) {
    const { dispatch } = useRedux();

    useEffect(() => {
        // set page title
        dispatch(changeSimplyAskDashboard(dashboard));
    }, [dispatch, dashboard]);
}
