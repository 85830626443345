// all routes
import Routes from './routes/Routes';
import regionalConfig from './config_region';
import ReactGA from 'react-ga4';

// helpers
import { configureFakeBackend } from './helpers';

// For Default import Theme.scss
import './assets/scss/Theme.scss';

ReactGA.initialize(regionalConfig.GA_TRACKING_ID);

const App = () => {
    configureFakeBackend();

    return (
        <Routes />
    );
};

export default App;
